<template>
  <div>
    <div class="air__utils__heading">
      <h3>customDropdownSelect tests</h3>
    </div>
    <a-row align="middle" :gutter="[8,24]">
      <a-col span="24">
        <h4>Select sencillo</h4>
      </a-col>
      <a-col :sm="12" :md="{ span:6}">
        <customDropdownSelect ref="servicePlanningsSelect"
                              v-model="servicePlanningsSelect"
                              label="Servicio"
                              resource="servicePlannings">
          <span slot-scope="{option}">
            {{ option.programming_code }}
          </span>
        </customDropdownSelect>
      </a-col>
      <a-col span="24">
        <h4>Select controlado desde orquestador</h4>
      </a-col>
      <a-col :sm="12" :md="{ span:6}">
        <b-button variant="primary" @click="FetchBtn">
          Fetch Compañias Transportistas
        </b-button>
      </a-col>
      <a-col :sm="12" :md="{ span:6}">
        <customDropdownSelect ref="carrierCompaniesSelect"
                              v-model="carrierCompaniesSelect"
                              label="Compañia Transportista"
                              resource="carrierCompanies"
                              :fetchWhenMounted="false"/>
      </a-col>
      <a-col span="24">
        <h4>Select dependiente</h4>
      </a-col>
      <a-col :sm="12" :md="{ span:6}">
        <customDropdownSelect ref="workCentersSelect"
                              v-model="workCentersSelect"
                              label="Planta"
                              resource="workCenters"/>
      </a-col>
      <a-col :sm="12" :md="{ span:6}">
        <customDropdownSelect ref="clientsSelect"
                              v-model="clientsSelect"
                              label="Cliente"
                              resource="clients"
                              :filters="workCenterId4ClientSelect"/>
      </a-col>
      <a-col span="24">
        <h4>Evento on change</h4>
      </a-col>
      <a-col :sm="12" :md="{ span:6}">
        <customDropdownSelect ref="chargingTypes"
                              v-model="chargingTypesSelect"
                              label="Tipo de Carga"
                              @change="handleChange"
                              optionValue="name"
                              resource="chargingTypes"/>
      </a-col>
      <a-col span="24">
        <b-button variant="secondary" @click="OpenModal">
          Select en Modal
        </b-button>
      </a-col>
    </a-row>
    <b-modal title="Ejemplo de Select en modal"
             ref="modal-form" size="xl" @hide="CloseModal">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="vertical">
        <customDropdownSelect ref="accountingTypes"
                              refAndPop="modalSelect"
                              v-model="form.modalSelect"
                              label="Tipo de Cuenta"
                              resource="accountingTypes"/>
        <b-button variant="primary" pill @click="Save">
          Guardar
        </b-button>
      </a-form-model>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'testingNewFunctions',
  data() {
    return {
      servicePlanningsSelect: undefined,
      clientsSelect: undefined,
      carrierCompaniesSelect: undefined,
      workCentersSelect: 8,
      chargingTypesSelect: undefined,
      form: {
        modalSelect: undefined,
      },
      rules: {
        modalSelect: [
          { required: true, message: 'El campo es obligatorio.', trigger: 'change' },
        ],
      },
    }
  },
  computed: {
    workCenterId4ClientSelect() {
      return {
        workCenterId: this.workCentersSelect,
      }
    },
  },
  methods: {
    FetchBtn() {
      this.$refs.carrierCompaniesSelect.Fetch()
    },
    OpenModal() {
      this.$refs['modal-form'].show()
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
    },
    Save() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          console.log('Valido')
        } else return false
      })
    },
    handleChange(value) {
      window.alert(`EcoNet says ${value}`)
    },
  },
}
</script>

<style scoped>
.border-bottom {
  border-width:3px !important;
}
</style>
